import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import Layout from '@/components/Layout.vue'

const routes = [
  {
    path: '/',
    redirect: { name: "Home" }
  }, 
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Home",
        meta: {
          requiresAuth: false,
          title: "NetVideo | Inicio"
        },
        props(route) {
          return  route.query || {}
        },
        component: () => import("../views/HomeView.vue"),
      }, 
      {
        path: "/check-in",
        name: "CheckInView",
        meta: {
          requiresAuth: false,
          title: "NetVideo | Checkin"
        },
        props(route) {
          return  route.query || {}
        },
        component: () => import("../views/CheckInView.vue"),
      }
    ]
  },

  // { path: '*', redirect: '/' } in vue js 2
  // default redirect to home page
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // to and from are both route objects. must call `next`.
  // set title on fly based on router title
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if(nearestWithTitle){
    document.title = nearestWithTitle.meta.title;
    store.commit('set_title', nearestWithTitle.meta.title)
  }

  if( 
    to.name == 'Login' 
    || to.name == 'Register'
  ) store.commit('set_show_nav_bar', false)
  else store.commit('set_show_nav_bar', true)

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      if(from.name != 'Home') {
        router.push({
          name: 'Home', query: {}, params: { id: to.params.id },
        })
      }
    } else {
      next()
    }
  } else {
    next()
  }

});

router.afterEach((to, from) => {
  if(to.name != 'Survey' && store.getters.isLoggedIn && store.getters.event.survey_enabled ===1 && !store.getters.already_answered) {
    if(from.name != 'Survey')
      router.push(
        {
          name:'Survey',
          query: {
            redirect_to: to.name,
          },
        }
      ).catch(err => { return err })
  }
 });

export default router
