<template>
    <div>
        <transition name="slide-left-right">
            <MsgBox v-if="show_msg_box" @closed="msgBoxClosed" :msg_data="msg_data" />
        </transition>
    </div>
</template>

<script>
import MsgBox from '@/components/Widgets/MsgBox.vue'

function addStyles (win, styles) {
  styles.forEach(style => {
    let link = win.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', style);
    win.document.getElementsByTagName('head')[0].appendChild(link);
  });
}

export default {
    name: 'AppModules',
    components: {
        MsgBox
    },
    data() {
        return {
            show_msg_box: false,
            msg_data: Object, default: () => ({
                header: null,
                message: null,
                lblBtnClose: null,
            })
        }
    },
    methods: {
        fetchStyle(url, printWindow) {  
            return new Promise((resolve, reject) => {

                try {
                    let file = printWindow.document.createElement('link');
                    file.rel = 'stylesheet';
                    file.href = url
                    printWindow.document.head.appendChild(file)
                    resolve(printWindow)
                } catch (error) {
                    console.log(error)
                    reject(null)
                }
                
            })
        },
        showMsgBox(msg) {
            this.msg_data = msg
            this.show_msg_box = true
        },
        msgBoxClosed() {
            console.log('closed message')
            this.show_msg_box=false
            this.msg_data = {}
        },
        goHome(msg) {
            if(msg) this.showMsgBox(msg)
            if(this.$route.name != 'Home' ) 
                this.$router.push(
                    {
                        name:'Home', 
                        params:{},
                        query: {
                            loc: this.$store.getters.location?this.$store.getters.location:''
                        }
                    }
                )
        },
        printContent(content) {
            const printWindow = window.open('', '', 'height=1000,width=1000')

            printWindow.document.write(`
                <html>
                <head>
                    <title>${window.document.title}</title>
                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
                    <link rel="preconnect" href="https://fonts.googleapis.com">
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                    <link href="https://fonts.googleapis.com/css2?family=Roboto" rel="stylesheet">
                    <style>

                    @page {
                        size: 3in 1in landscape;
                    }

                    body {
                        font-family: "Roboto", sans-serif;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 0.8in;
                    }

                    </style>
                </head>
                <body>
                    ${content}
                </body>
                </html>
            `);

            //addStyles(printWindow, ['https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css']);
            
            setTimeout(() => {
                printWindow.focus();
                printWindow.print();
                setTimeout(function () {printWindow.close();}, 1);
            }, 1000);
            
        }
    },
    computed: {
        event : function() { return this.$store.getters.event },
    },
    created () {
        this.emitter.on('showMsgBox', this.showMsgBox);
        this.emitter.on('goHome', this.goHome);
        this.emitter.on('printContent', this.printContent);
    },
}
</script>

<style scoped lang="scss">
@import '~@/styles/app';



</style>